@import "../../styles/animations.scss";
@import "../../styles/variables.scss";

.leftSideContainer {
    background-color: #F2F2F2;
    width: 50%;
    padding: 30px;
    overflow: hidden;
    position: relative;
    z-index: -1;

    .logoContainer {
      img {
        min-height: 44px;
        max-height: 55px;

        @media screen and (max-width: $breakpoint-tablet) {
          height: 22px;
          width: 100%;
          margin-bottom: 1rem;
        }
      }

      @media screen and (max-width: breakpoint-netbook) {
        text-align: center;
      }
    }

    .imageContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .active {
        animation: fadeIn linear 2s;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and(min-width: $breakpoint-tablet) and (max-width: $breakpoint-netbook) {
          align-items: flex-start;
        }
      }

      .inactive {
        display: none;
      }

      img {
        max-width: 440px;

        @media screen and (max-width: $breakpoint-tablet) {
          width: 40%;
        }

        @media screen and(min-width: $breakpoint-tablet) and (max-width: $breakpoint-netbook) {
          width: 80%;
        }

        @media screen and(min-width: $breakpoint-netbook) and (max-width: 1200px) {
          // width: 80%;
        }
      }

      // @media screen and(min-width: $breakpoint-tablet) and (max-width: $breakpoint-netbook) {
      //   height: auto;
      // }

    }

    @media screen and (max-width: $breakpoint-tablet) {
        width: 100%;
    }
}

