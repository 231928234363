$primary: #00758c;
$trials: #008263;
$secondary: #0075b2;
$tertiary: #8f9194;
$quaternary: #f2f2f2;
$quinary: #666666;
$quinary-hover: #4d4d4d;
$dark: #292929;
$denial: #9b1a06;
$process: #f5c434;
$white: #ffffff;
$hr: #cccccc;
$black: #292929;
$secondary-hover:#0069a0;
$primary-hover: #248094;


$breakpoint-tablet: 768px;
$breakpoint-netbook: 991px;
