@import "../../styles/variables.scss";

.container {
  display: flex;
  width: 100%;
  min-height: 100vh;

  @media screen and (max-width: $breakpoint-tablet) {   
    display: inline-block; 
    width: 100%;
  }

  section {
    width: 50%;
    padding: 30px;
    padding-top:0;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.5);

    @media screen and (max-width: $breakpoint-tablet) {    
      width: 100%;
    }
  }
}