@import "../../styles/animations.scss";
@import "../../styles/variables.scss";

.button {
  border-radius: 2px;
  width: 100%;
  margin: 1rem 0 0 0;
  border: none;
  height: 44px;
  font-family: "Roboto", sans-serif;
  color: $white;
  outline: none;
  font-weight: 500;
  box-sizing: border-box;
  font-size: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s;


}

.blue {
  background: $secondary;

  &:hover {
    background: $secondary-hover;
  }
}

.trials {
  background: $trials;

  &:hover {
    filter: brightness(90%);
  }
}

.platform {
  background: $primary;

  &:hover {
    background: $primary-hover;
  }
}

.button i {
  font-size: 14px;
  animation: sliceUp 0.4s ease forwards;
}

.button:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.lottieContainer {
  display: flex;
  justify-content: center;
  
  .lottie {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    width: 25px;
    height: 25px;
  }
}