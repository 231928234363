@import "../../styles/variables.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 44px;
  padding-top:90px;

  @media screen and (max-width: $breakpoint-tablet) {
    padding-top:0;
    margin-bottom: 90px;
  }

  .registerSection {
    @media only screen and (max-width: $breakpoint-tablet) {
      min-height: auto;
      margin-bottom: 50px;
    }
  }

  .registerStepTwo {
    @media only screen and (max-width: $breakpoint-tablet) {
      min-height: auto;
    }
  }

  .formContainer, .registerSection, .registerStepTwo {
    width: 100%;
    max-width: 490px;
    position: relative;

    @media only screen and (max-width: $breakpoint-tablet) {
      max-width: 100%;
      margin-bottom: 50px;
    }

    .back {
      position: absolute;
      top: 10px;
      left: -60px;
      cursor: pointer;

      &:hover {
        svg {
          g {
            transition: 0.4s ease;
            stroke: $primary;
          }
        }
      }
    }

    .form {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      flex-direction: column;

      p {
        margin-bottom: 1rem;
      }

      .error {
        color:$denial
      }

      .passwordValidationContainer {
        margin-bottom: 1rem;
        margin-top: 0.25rem;
        font-size: 12px;
        color:$quinary;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
    
        li {
            display: flex;
            align-items: center;
            list-style: none;

            .success {
              color:#3d9970;
            }

            .danger {
              color:#ff3860;
            }
        }
      }
      .disclaimer {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 14px;
        color:$quinary;
        font-family: "Roboto", sans-serif;
        font-weight: 400;

        .links {
          color: $secondary;
        }
      }
      
    }

    .recoverLink {
      margin-top: 1rem;
      color: $secondary;
      font-size: 0.875rem;
      cursor: pointer;
      text-decoration: none;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      border: none;
      background: none;
    }

    .separator {
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      p {
        display: flex;
        justify-content: center;
        width: 50px;
        background-color: $white;
        font-size: 14px;
        color:$tertiary;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        border-top: 1px solid $hr;
        background: $hr;
        width: 100%;
        transform: scaleY(0.5);
        z-index: -1;
      }
    }

    .bookCall {
      display: flex;
      align-items: center;
      justify-content: center;

      width:100%;
      height: 44px;
      background: $secondary;
      border-radius: 2px;
      width: 100%;
      margin: 1rem 0 0 0;
      border: none;
      height: 44px;
      font-family: "Roboto", sans-serif;

      outline: none;
      font-weight: 500;
      box-sizing: border-box;
      font-size: 1rem;
      cursor: pointer;
      text-transform: uppercase;

      .link {
        color: $white;
      }
    }
  }
}
