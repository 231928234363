@import "../../styles/variables.scss";

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 70%;

    font-size: 12px;
    color:$black;
    font-family: "Roboto", sans-serif;
    font-weight: 400;

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @media screen and (max-width: $breakpoint-tablet) {
        width: 100%;
    }

    hr {
        background-color: $hr;
        border: none;
        min-width: 50px;
        height: 2px;
    }

    .stepContainer {
        display: flex;

        .number {
            margin-right: 0.25rem;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20px;
            width: 20px;
            background-color: $tertiary;
            color: $white;
            border-radius: 50%;
        }
    
        .text {
            display: flex;
            align-items: center;
            font-size: 12px;
            color:$black;
            font-family: "Roboto", sans-serif;
            font-weight: 400;
        }

    }

    .hractive {
        background-color: $primary;
        -webkit-animation: animate 0.5s ease-in;  
        animation: animate 0.5s ease-in;
    }

    .active {
        .number {
            background-color: $primary;
            -webkit-animation: animate 0.5s ease-in;  
            animation: animate 0.5s ease-in;
        }
    }
}

@keyframes animate {
    0% {background-color: $quaternary;}
    100% {background-color: $primary} 
}
