@import "../../styles/variables.scss";
@import "../../styles/animations.scss";

.cookieContainer {
    position: fixed;
    bottom: 0;
    padding: 2rem;
    background-color: $white;
    z-index: 10;

    @media screen and (max-width: $breakpoint-tablet) {
        left: 0;
        bottom: 100px;
        z-index: 10;
    }
    
    &.show {
        animation: sliceLeftIn linear 1s;
    }

    &.hide {
        animation: sliceLeftOut linear 1s;
    }

    .modalContainer {
        max-width: 24rem;

        .textContainer {
            margin-bottom: 1em;
            font-family: "Nunito Sans", sans-serif;
            font-size: 1rem;
            line-height: 1.5em;

            p {
                max-width: 70%;
            }

            a {
                padding: 0.2rem;
                opacity: 0.8;
                color: $secondary;
            }
        }

        button {
            font-family: "Nunito Sans", sans-serif;
            font-size: 1rem;
            line-height: 1.5em;
            width: 100%;
            min-height: 40px;
            border-radius: 4px;
            color: $white;
            display: block;
            padding: 0.4em 0.8em;
            font-size: 0.9em;
            text-align: center;
            white-space: nowrap;

            border: 2px solid;
            border-color: transparent;

            cursor: pointer;
            transition: 0.3s;

        }

        @media screen and (max-width: $breakpoint-tablet) {
            max-width: 24rem;
        }

        @media screen and(min-width: $breakpoint-tablet) and (max-width: $breakpoint-netbook) {
            max-width: 18rem;
        }
    }
}