.container {
    display: flex;
    width: 100%;
    min-height: 100%;

    .sideContainer {
        width:100%;

        .creditsContainer {
            padding: 2rem 1.5rem;

            .creditsBox {
                margin-top: 3rem;

                .creditsItems {
                    margin-top: 1.5rem;
                }
            }
        }
    }
}