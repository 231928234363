@import "../../styles/variables.scss";

.container {
    display: flex;
    width: 100%;
    min-height: 100%;
    
    .sideContainer {
        width:100%;

        .dataContainer {
            padding: 2rem 1.5rem;
            max-width: 35%;

            @media only screen and (max-width: $breakpoint-tablet) {
                max-width: 100%;
            }

            h2 {
                margin-bottom: 2rem;
            }
        }
    }
}