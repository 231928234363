@import "../../styles/variables.scss";

.container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 44px;

  @media screen and (max-width: $breakpoint-tablet) {
    margin-bottom: 90px;
  }

  .formContainer {
    width: 100%;
    max-width: 390px;
    position: relative;

    .back {
      position: absolute;
      top: 10px;
      left: -60px;
      cursor: pointer;

      &:hover {
        svg {
          g {
            transition: 0.4s ease;
            stroke: $primary;
          }
        }
      }
    }

    .form {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: auto;

      @media only screen and (max-width: $breakpoint-tablet) {
        margin-left: -15px;
      }

      p {
        margin-bottom: 1rem;
      }
    }
  }
}
