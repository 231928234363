@import "../../styles/variables.scss";

.container {
    display: flex;
    width: 100%;
    min-height: 100%;
    
    .sideContainer {
        width:100%;

        .changePasswordContainer {
            padding: 2rem 1.5rem;
            max-width: 35%;

            @media only screen and (max-width: $breakpoint-tablet) {
                max-width: 100%;
            }

            h2 {
                margin-bottom: 2rem;
            }

            .passwordValidationContainer {
                margin-bottom: 1rem;
                margin-top: 0.25rem;
                font-size: 12px;
                color:$quinary;
                font-family: "Roboto", sans-serif;
                font-weight: 400;
            
                li {
                    display: flex;
                    align-items: center;
                    list-style: none;
        
                    .success {
                    color:#3d9970;
                    }
        
                    .danger {
                    color:#ff3860;
                    }
                }
            }
        }
    }
}