@import "../../styles/variables.scss";

.twoFactorWarningContainer {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 12px;
  color: $quinary;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: justify;

  li {
      display: flex;
      align-items: start;
      list-style: none;
      font-size: 12px;
      text-align: justify;

      svg {
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          margin-right: 0.5rem;
      }
  }
}