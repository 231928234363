@import "../../styles/variables.scss";

.container {
    display: flex;
    width: 100%;
    min-height: 100vh;

    .loadingSection {
        width: 50%;
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
            text-align: center;
        }

        @media only screen and (max-width: $breakpoint-tablet) {
            align-items:flex-start;
            width: 100%;
        }

        .lottie {
            height: 300px;
            width: 300px;
            margin: 0 auto;

            @media only screen and (max-width: $breakpoint-tablet) {
                height: 150px;
                width: 150px;

            }
        }
    }
}