@import "./styles/variables.scss";


@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400&display=swap');

* {
  padding: 0;
  margin: 0;
}

section {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  position: relative;
  min-height: 100vh;

  @media only screen and (max-width: $breakpoint-tablet) {
    min-height: calc(100vh - 250px);
  }
}

a { 
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Nunito Sans', sans-serif;
  padding-bottom: 0.5rem;
  line-height: 1.375em;
  font-weight: normal;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
  i {
    vertical-align: middle;
    margin-top: -2px;
  }
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}



p {
  color: #292929;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  line-height: 1.5;
}

*::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: $breakpoint-tablet) {
  .grecaptcha-badge { 
    display: none !important;
  }
}