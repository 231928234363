@import "../../styles/variables.scss";

.container {
    display: flex;
    width: 100%;
    min-height: 100%;
    
    .sideContainer {
        width:100%;

        .twoFactorContainer {
            padding: 2rem 1.5rem;

            max-width: 35%;

            @media only screen and (max-width: $breakpoint-tablet) {
                max-width: 100%;
            }
    
            h2 {
                margin-bottom: 2rem;
            }
    
            p {
                margin-bottom: 1rem;
            }
    
            .switchContainer {
                display: flex;
                margin-bottom: 2rem;



                p {
                    margin-bottom: 0;
                    margin-right: 12px;
                    min-height: 40px;
                    display: flex;
                    align-items: center;

                    &.disabled {
                        color: rgba(0, 0, 0, 0.38);
                    }
    
                    &.enabled {
                        color: $black;
                    }
                }
            }
        }
    }
}