@import "../../../styles/variables.scss";
@import "../../../styles/animations.scss";

.registerForm {
    position: fixed;
    width: 50%;
    right: 0;
    top: 0;
    padding-top: 30px;
    padding-right: 30px;
    padding-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    height: auto;
    background: $white;
    border-bottom: 1px solid $hr;
    z-index: 10;

    @media only screen and (max-width: $breakpoint-tablet) {
        width: 100%;
        flex-direction: column;
        background: $quaternary;
        border-top: 1px solid $quaternary;
        box-shadow: 0px -.7px 3px rgba(50, 50, 50, 0.75);
        bottom: 0;
        justify-content: center;
        padding-right: 0;
        height: auto;
        max-height: min-content;
        top: 80%;
        margin-top: 2.5rem;
    }
}

.registerText {
    display: flex;
    flex: 1/2;
    align-items: center;

    @media only screen and (max-width: $breakpoint-tablet) {
        flex: 1;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

}

.buttonContainer {
    flex: 1/2;
    border: none;
    margin-left: 0.625rem;

    @media only screen and (max-width: $breakpoint-tablet) {
        flex: 1;
        margin-left: 0;
        margin-top: 1rem;
        padding: 0 30px 30px;
    }

    .registerButton {
        border: 1px solid $dark;
        border-radius: 2px;
        display: flex;
        align-content: center;
        min-width: 150px;
        cursor: pointer;
        
        min-height: 44px;
        -webkit-justify-content: center;
        justify-content: center;
        align-items: center;
        padding: 0.25rem 2rem;
        text-align: center;
        
        -webkit-box-shadow: 0px 1px 2px 0px transparentize($dark, 0.4);
        -moz-box-shadow: 0px 1px 2px 0px transparentize($dark, 0.4);
        box-shadow: 0px 1px 2px 0px transparentize($dark, 0.4);
        transition: background-color 0.3s ease;
        font-size: 1rem;
        font-weight: 500;
        color: $dark;
        text-transform: uppercase;
        background-color: $white;
        font-family: "Roboto", sans-serif;
    
        &:hover {
            background-color: $dark;
            color:$white
        }
    }
}

