@import "../../styles/variables.scss";

.container {
    position: relative;
    background-color: $quaternary;
    width: 25%;
    min-height: calc(100vh - 90px);

    @media only screen and (max-width: $breakpoint-tablet) {
        display:none;
    }

    &.fixed {
        width: 60%;
        position: absolute;
        right:0;
        animation: sliceLeftIn linear 1s;
        z-index: 100;
        height: 100%;
    }

    .itemContainer {
        padding: 1.5rem 1rem;

        .item {
            padding: 0.5rem 0;

            &:nth-child(2), &:nth-child(4), &:nth-child(6) {
                border-top: 1px solid $hr;
                border-bottom: 1px solid $hr;
            }

            .active {
                color: $primary
            }
        }

    }
}

