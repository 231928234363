@import "../../styles/variables.scss";

.container {
  width: 100%;

  .navigationBox {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 1rem;
    border-bottom: 1px solid $quaternary;

    .titleContainer {
        display: flex;
        align-items: center;
        h1 {
            min-width: 200px;
            padding-bottom: 0;
            font-size: auto;

            @media only screen and (max-width: $breakpoint-tablet) {
                min-width: 100px;
                font-size: 1rem;
            }
        }
    }

    .imageContainer {
        max-height: 40px;
        text-align: center;
        width: 100%;

        @media only screen and (max-width: $breakpoint-tablet) {
            width: 50%;
            text-align: left;
        }

        img {
            height: 100%;

            @media only screen and (max-width: $breakpoint-tablet) {
                height: 20px;
            }
        }
    }

    .hamburguerContainer {
        display: flex;
        align-items: center;

        .itemContainer {
            padding: 1.5rem 1rem;
    
            .item {
                padding: 0.5rem 0;
    
                &:nth-child(2), &:nth-child(4) {
                    border-top: 1px solid $hr;
                    border-bottom: 1px solid $hr;
                }
    
                .active {
                    color: $primary
                }
            }
    
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            display:none;
        }

    }

    .helpContainer {
        display: flex;
        a {
            display: flex;
            align-items: center;
            i {
                margin-right: 10px;
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            display:none;
        }
    }
  }
}